.redirect-text {
    font-family: 'DBSans', Arial, sans-serif;
    font-size: 16px;
    color: #333333;
    line-height: 22px;
}

.redirect-mt2 {
    margin-top: 2em;
}

.redirect-mt1 {
    margin-top: 1em;
}

.redirect-link {
    color: #F01414;
    cursor: pointer;
}

.redirect-mb4{
    margin-bottom: 4em;
}

body{
    font-family: DBSans, Arial, sans-serif;
    margin: 0px;

}

.header-hd {
    margin: 0;
    padding: 0;
    border-bottom: 24px solid #878c97;
    height: 124px;
    background-color: #FFFFFF;
}

.header-hd #header-small-links {
    display: block;
    width: 100%;
    height: 40px;
    background-color: #E4E4E4;
    line-height: 30px;
    position: relative;
}

.header-hd .inner {
    position: relative;
}

.header-hd .inner {
    margin: 0 auto;
}

.header-hd .inner, .header-bd, .header-ft {
    width: 974px;
    margin: 0 auto 15px;
    clear: both;
    height: 100%;
    text-align: left;
}

.header-hd #header-small-links ul {
    padding-top: 15px;
    list-style: none outside;
    width: 974px;
    margin: 0 auto;
}

.header-hd #header-small-links ul li {
    display: block;
    float: left;
    border-right: 1px solid #333333;
    padding: 0 10px;
    line-height: 10px;
    font-size: 11px;
}

.header-hd #header-small-links ul li a {
    padding: 7px 0;
    color: #333333;
    text-decoration: none;
    font-family: DBSans, Arial, sans-serif;
    font-size: 11px;
}

#main_logo {
    display: block;
    float: left;
    margin: 20px 0 0 0;
    height: 39px;
    background-image: url(./assets/images/logo.png);
    background-repeat: no-repeat;
    background-size: 155px;
}

#main_logo a {
    display: block;
    padding: 2px 0 0 80px;
    font-family: "DBSans", Arial, sans-serif;
    color: black;
    font-size: 30px;
    text-decoration: none;
}

header-hd .inner {
    position: relative;
}
.header-hd .inner {
    margin: 0 auto;
}
.header-hd .inner, .header-bd, .header-ft {
    width: 974px;
    margin: 0 auto 15px;
    clear: both;
    height: 100%;
    text-align: left;
}

#ft {
    margin-top: 10px;
}
#ft .bd {
    width: 100%;
    background-color: #E7E7E7;
}

#ft .ft {
    width: 100%;
    height: 76px;
    background-color: #878c97;
    background-image: url(./assets/images/footer-winkel.png);
    background-size: 5000px 76px;
    background-position: center center;
    background-repeat: no-repeat;
}

#ft .ft .container {
    width: 974px;
    height: 100%;
    margin: 0 auto;
    background-color: #878c97;
}

#ft .ft .container .copyright {
    display: block;
    float: left;
    height: 47px;
    text-align: left;
    color: #FFFFFF;
    font-family: DBSans, Arial, sans-serif;
    font-size: 13px;
    line-height: 47px;
}

#ft .ft .container .sub_menu_socket {
    display: block;
    float: right;
    height: 47px;
    text-align: left;
    color: #FFFFFF;
    font-family: DBSans, Arial, sans-serif;
    font-size: 13px;
    line-height: 47px;
    border-bottom: 29px solid #FFFFFF;
    padding-left: 10px;
}

#ft .ft .container .sub_menu_socket li {
    float: left;
    padding-left: 14px;
    list-style: none;
}

#ft .ft .container .sub_menu_socket li a {
    color: #FFFFFF;
    text-decoration: none;
}


.yui-t7 {
    margin: auto;
    text-align: left;
    width: 57.69em;
    *width: 56.25em;
}

#yui-main {
    clear: both;
}

.yui-t7 #yui-main .yui-b {
    display: block;
    margin: 0 0 1em 0;
}


#ft .bd .container .flex_column {
    float: left;
    margin-left: 3%;
    width: 19.0%;
}
#ft .ft .container .sub_menu_socket li {
    float: left;
    padding-left: 14px;
}

ul{
    margin: 0;
    padding: 0;
}

